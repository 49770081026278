<template>
  <Drawer v-if="audit.active && project">
    <div class="drawer-header p-3 d-flex align-items-center justify-content-between">
      <h4>Audits</h4>
      <nav class="nav nav-pills">
        <a
          v-if="!isExternalAuditor && !isDESNZViewer && !isOfgemRetrofitDataViewer && !isSchemeInstaller"
          id="desktop-audit"
          class="nav-link py-1"
          href="#"
          :class="{ active: audit.view === 'desktop' }"
          @click.prevent="audit.view = 'desktop'"
          ><FormPopover content="Desktop" trigger="hover" :icon="audit.view === 'desktop' ? 'bi bi-pc-display-horizontal text-white' : 'bi bi-pc-display-horizontal text-primary'"
            ></FormPopover
          ></a
        >
        <a
          id="onsite-audit"
          v-if="isOnsiteAuditor"
          class="nav-link py-1"
          href="#"
          :class="{ active: audit.view === 'onsite' }"
          @click.prevent="audit.view = 'onsite'"
          ><FormPopover content="Onsite" trigger="hover" :icon="audit.view === 'onsite' ? 'bi bi-house text-white' : 'bi bi-house text-primary'"
            ></FormPopover
          ></a
        >
        <a
          id="bespoke-audit"
          v-if="isBespokeAuditor && !isDESNZViewer && !isOfgemRetrofitDataViewer && !isSchemeInstaller"
          class="nav-link py-1"
          href="#"
          :class="{ active: audit.view === 'bespoke' }"
          @click.prevent="audit.view = 'bespoke'"
          ><FormPopover content="Bespoke" trigger="hover" :icon="audit.view === 'bespoke' ? 'bi bi-gear text-white' : 'bi bi-gear text-primary'"
            ></FormPopover
          ></a
        >
      </nav>
      <button class="drawer-dismiss btn text-primary" @click="audit.active = false">
        <i class="bi bi-x-lg" />
      </button>
    </div>
    <AuditOnsite v-if="audit.view === 'onsite'" :project="project" :lodgement="lodgement" />
    <AuditDesktop
      v-else-if="audit.view === 'desktop'"
      :project="project"
      :lodgement="lodgement"
      :can-audit="canAudit"
    />
    <AuditBespoke v-else-if="audit.view === 'bespoke'" :project="project" :lodgement="lodgement" />
  </Drawer>
</template>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAppStore } from '../stores/app';
import { useAuditStore } from '../stores/audit';
import { useLodgementsStore } from '../stores/lodgements';
import { useProjectsStore } from '../stores/projects';
import { useAuthStore } from '../stores/auth';
import AuditDesktop from './AuditDesktop.vue';
import AuditOnsite from './AuditOnsite.vue';
import AuditBespoke from './AuditBespoke.vue';
import Drawer from './Drawer.vue';
import FormPopover from './FormPopover.vue';

const route = useRoute();
const { isTM, isSchemeInstaller } = storeToRefs(useAppStore());

const { project } = storeToRefs(useProjectsStore());
const { lodgement } = storeToRefs(useLodgementsStore());
const audit = useAuditStore();
const authStore = useAuthStore();

const isOnsiteAuditor = computed(() => {
  return authStore.account?.roles?.indexOf('OnsiteAuditViewer') > -1;
});

const isBespokeAuditor = computed(() => {
  return authStore.account?.roles?.indexOf('BespokeAuditViewer') > -1;
});

const isExternalAuditor = computed(() => {
  return authStore.account?.roles?.includes('ExternalAuditor');
});

const isDESNZViewer = computed(() => {
  return authStore.isDESNZViewer;
});
const isOfgemRetrofitDataViewer = computed(() => authStore.isOfgemRetrofitDataViewer);

const canAudit = computed(() => {
  let isAuditableSection = false;
  if (typeof route.meta?.audit === 'boolean') {
    isAuditableSection = route.meta.audit;
  } else if (typeof route.meta?.audit === 'function') {
    isAuditableSection = route.meta.audit({
      project: project.value,
      lodgement: lodgement.value,
      route,
    });
  }
  const isNotDraft = project.value?.status !== 'Draft';
  return isTM.value && isNotDraft && isAuditableSection;
});

watch(route, (to) => {
  // Hide the panel if route does not allow auditing
  if (!('audit' in to.meta)) {
    audit.active = false;
    return;
  }
});

onBeforeUnmount(() => {
  audit.active = false;
});

onBeforeMount(() => {
  if (isDESNZViewer.value) {
    audit.view = 'onsite';
  }
  if (isOfgemRetrofitDataViewer.value) {
    audit.view = 'onsite';
  }
});
</script>

<style lang="scss">
.audit-section {
  padding: 1rem;
  &:not(:first-child) {
    border-top: $border-width solid $border-color;
  }
}
</style>
